import "./L2TxLogCard.css";
import { memo, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import useCheckIssuerAlias from "utils/checkIssuerAlias";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Alias } from "shared/components";
import CircularProgress from "@mui/material/CircularProgress";

type TxContractExecutedRowProps = {
  row: any;
};

const TxContractExecutedRow = (props: TxContractExecutedRowProps) => {
  const { contractTemplates, interpreters, contractInstance } =
    useGlobalIndex();
  const { row } = props;

  const [alias, setAlias] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [contentOpen, setContentOpen] = useState(false);
  const [issuerOpen, setIssuerOpen] = useState(false);
  const [opsArray, setOpsArray] = useState(() => {
    return new Array(row[0]?.ops?.length).fill(false); // Create an array filled with false values
  });
  const op = row[0]?.ops?.find((op: any) => Object.keys(op)[0] === "Verified");
  const issuer = op
    ? JSON.stringify(Object.values(op[Object.keys(op)[0]] ?? op)[1], null, 2)
    : undefined;

  const issuerAlias = useCheckIssuerAlias(
    issuer || "",
    interpreters,
    contractTemplates,
    contractInstance
  );

  useEffect(() => {
    if (issuerAlias.loading && !issuerAlias.alias) {
      setAlias("loading");
    } else if (!issuerAlias.loading && issuerAlias.alias) {
      setAlias(issuerAlias.alias);
    } else setAlias(undefined);
  }, [issuerAlias]);

  const handleOpsArray = (index: number) => {
    setOpsArray((prevState) => {
      const newArray = [...prevState]; // Create a copy of the original array
      newArray[index] = !newArray[index]; // Toggle the boolean value at the specified index
      return newArray; // Return the updated array
    });
  };

  const handleContent = (val: any) => {
    if (val || val[0]) {
      const contentKeys = Object.keys(val[0] ?? val);
      const contentValues = Object.values(val[0] ?? val);
      if (
        contentKeys[0] &&
        contentKeys[0] === "body" &&
        contentKeys[1] &&
        contentKeys[1] === "fees_stored" &&
        contentKeys[2] &&
        contentKeys[2] === "key"
      ) {
        return (
          <div>
            <div className="callOpContainer">
              <Typography className="BoldWhiteFont14">Body</Typography>

              <pre
                style={{
                  height: "100%",
                  overflow: "scroll",
                  marginBottom: "0px !important",
                }}
              >
                <Typography className="DarkThinFont10 DisplayJsonRaw">
                  {JSON.stringify(contentValues[0], null, 2) ?? "N/A"}
                </Typography>
              </pre>
            </div>
            <div
              className="callOpContainer"
              style={{ marginTop: "5px !important" }}
            >
              <Typography className="BoldWhiteFont14">Fees Stored</Typography>
              <Typography className="DarkThinFont10 DisplayJsonRaw">
                {JSON.stringify(contentValues[1], null, 2) ?? "N/A"}
              </Typography>
            </div>
            <div className="callOpContainer">
              <Typography
                className="BoldWhiteFont14"
                sx={{ marginTop: "10px" }}
              >
                Key{" "}
              </Typography>
              <Typography className="DarkThinFont10  DisplayJsonRaw">
                {JSON.stringify(contentValues[2], null, 2) ?? "N/A"}
              </Typography>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <TableCell
        onClick={() => setOpen(!open)}
        className="operationCollapseCell"
      >
        <IconButton aria-label="expand row" size="small">
          {open ? (
            <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
          )}
        </IconButton>
        <Typography className="BoldBlueFont16">Operation Details</Typography>
      </TableCell>

      <TableCell className="operationCollapseCell">
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div>
            {row[0]?.ops?.map((op: any, index: number) => {
              const opType = Object.keys(op)[0];
              const opValue = Object.values(op);

              if (opType === "Verified") {
                const argValues = Object.values(opValue[0] ?? opValue);

                return (
                  <div style={{ marginTop: "10px" }} key={index}>
                    <TableCell
                      onClick={() => handleOpsArray(index)}
                      className="operationCollapseCell"
                    >
                      <IconButton aria-label="expand row" size="small">
                        {opsArray[index] ? (
                          <KeyboardArrowUpIcon sx={{ color: "#fff" }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                        )}
                      </IconButton>
                      <Typography className="BoldWhiteFont14">
                        Forwarded Claim
                      </Typography>
                    </TableCell>
                    <Collapse in={opsArray[index]} timeout="auto" unmountOnExit>
                      <div>
                        <TableCell
                          onClick={() => setContentOpen(!contentOpen)}
                          className="operationCollapseCell"
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <IconButton aria-label="expand row" size="small">
                            {contentOpen ? (
                              <KeyboardArrowUpIcon
                                sx={{ color: "rgb(0, 195, 255)" }}
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                sx={{ color: "rgb(0, 195, 255)" }}
                              />
                            )}
                          </IconButton>
                          <Typography
                            className="BoldBlueFont14"
                            sx={{ paddingTop: "0px", width: "auto" }}
                          >
                            Content
                          </Typography>
                        </TableCell>
                        <Collapse in={contentOpen} timeout="auto" unmountOnExit>
                          {handleContent(argValues[0])}
                        </Collapse>
                      </div>
                      <div>
                        <TableCell
                          onClick={() => setIssuerOpen(!issuerOpen)}
                          className="operationCollapseCell"
                        >
                          <IconButton aria-label="expand row" size="small">
                            {issuerOpen ? (
                              <KeyboardArrowUpIcon
                                sx={{ color: "rgb(0, 195, 255)" }}
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                sx={{ color: "rgb(0, 195, 255)" }}
                              />
                            )}
                          </IconButton>
                          <Typography
                            className="BoldBlueFont14"
                            sx={{ paddingTop: "0px", width: "auto" }}
                          >
                            Issuer
                          </Typography>
                        </TableCell>
                        <Collapse in={issuerOpen} timeout="auto" unmountOnExit>
                          {alias === "loading" ? (
                            <CircularProgress />
                          ) : alias === undefined ? (
                            <Typography className="DarkThinFont10 DisplayJsonRaw">
                              {JSON.stringify(argValues[1], null, 2)}
                            </Typography>
                          ) : (
                            <Alias alias={alias} />
                          )}
                        </Collapse>
                      </div>
                    </Collapse>
                  </div>
                );
              } else {
                return (
                  <div style={{ marginTop: "10px" }} key={index}>
                    <TableCell
                      onClick={() => handleOpsArray(index)}
                      className="operationCollapseCell"
                    >
                      <IconButton aria-label="expand row" size="small">
                        {opsArray[index] ? (
                          <KeyboardArrowUpIcon sx={{ color: "#fff" }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                        )}
                      </IconButton>
                      <Typography className="BoldWhiteFont14">
                        Argument
                      </Typography>
                    </TableCell>
                    <Collapse in={opsArray[index]} timeout="auto" unmountOnExit>
                      <Typography className="DarkThinFont10 DisplayJsonRaw">
                        {JSON.stringify(opValue, null, 2)}
                      </Typography>
                    </Collapse>
                  </div>
                );
              }
            })}
          </div>
        </Collapse>
      </TableCell>
    </div>
  );
};

export default memo(TxContractExecutedRow);
