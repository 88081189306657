import {
  NetworkName,
  BlockFragment,
  FullTxLogFragment,
  GqlClaimInfo,
} from "../../generated/graphql";
import { useCallback } from "react";
import { getBlock, getFectchedClaims, getL2Txs } from "utils/queryRequests";
import { useQueries } from "@tanstack/react-query";
import LoadingBox from "../LoadingBox";
import TableData from "../TableData";
import L2TxLogCard from "../L2TxLogCard/L2TxLogCard";
import ClaimDataCard from "../L2TxLogCard/ClaimDataCard";
import { REACT_APP_ENABLED_NETWORKS } from "../../conf";
import { ClaimData } from "utils/types";
import { isNumeric } from "utils/isNumeric";
import { DataNotFoundSection } from "./DevNetworkSelector";
type Props = {
  setNetwork: (n: NetworkName | undefined) => void;
  hash: string;
  width: number;
  onSetSubscription: (value: boolean) => void;
};

const NetworkSelector: React.FC<Props> = ({
  setNetwork,
  hash,
  width,
  onSetSubscription,
}) => {
  const isNumericHash: boolean = isNumeric(hash);
  const blockRes = useQueries({
    queries: REACT_APP_ENABLED_NETWORKS.map((network) => {
      return {
        queryKey: ["block", hash, network],
        queryFn: () => getBlock(hash, network),
        enabled: !isNumericHash,
        staleTime: 1000 * 60 * 60,
      };
    }),
  });

  const l2TxRes = useQueries({
    queries: REACT_APP_ENABLED_NETWORKS.map((network) => {
      return {
        queryKey: ["l2tx", hash, network],
        queryFn: () => getL2Txs(hash, network),
        enabled: !isNumericHash,
        staleTime: 1000 * 60 * 60,
      };
    }),
  });

  const fetchClaimRes = useQueries({
    queries: REACT_APP_ENABLED_NETWORKS.map((network) => {
      return {
        queryKey: ["claims", network, hash],
        queryFn: () => getFectchedClaims(network, Number(hash)),
        enabled: isNumericHash,
        staleTime: 1000 * 60 * 60,
      };
    }),
  });

  const handleDisplay = useCallback(() => {
    const allFetchedBlock = blockRes.every((result) => !result.isLoading);
    const allFetchedL2Tx = l2TxRes.every((result) => !result.isLoading);
    const allFetchedClaims = fetchClaimRes.every((result) => !result.isLoading);

    if (!allFetchedBlock || !allFetchedL2Tx || !allFetchedClaims)
      return <LoadingBox>Searching...</LoadingBox>;

    if (!isNumericHash && allFetchedBlock && allFetchedL2Tx) {
      const filteredBlockRes = blockRes.filter(
        (result: any) =>
          result?.data !== undefined && result?.data?.block !== null
      ); // Filter block results with data
      const filteredL2txRes = l2TxRes.filter(
        (result: any) =>
          result?.data !== undefined && result?.data?.l2TxFullLog?.length > 0
      );

      if (Array.isArray(filteredBlockRes) && filteredBlockRes.length > 0) {
        return (
          <TableData
            width={width}
            data={[
              (filteredBlockRes[0].data as any)
                ?.block as unknown as BlockFragment,
            ]}
            onSetSubscription={onSetSubscription}
            setNetwork={setNetwork}
          />
        );
      }
      if (Array.isArray(filteredL2txRes) && filteredL2txRes.length > 0) {
        // console.log("filteredL2txRes", filteredL2txRes);
        return (
          <div>
            {filteredL2txRes.map((log: any, index) => (
              <L2TxLogCard
                key={
                  (log?.data?.l2TxFullLog[0] as unknown as FullTxLogFragment)
                    .txid
                }
                width={width}
                data={log?.data?.l2TxFullLog[0] as unknown as FullTxLogFragment}
                setNetwork={setNetwork}
              />
            ))}
          </div>
        );
      }
    }

    if (isNumericHash && fetchClaimRes) {
      return fetchClaimRes.map((claimRes, index) => {
        if (
          claimRes.data &&
          claimRes?.data?.res?.network?.fetchClaim !== null
        ) {
          const fetchClaimData: ClaimData = {
            data: claimRes?.data?.res?.network?.fetchClaim as GqlClaimInfo,
            network: claimRes.data?.network,
          };

          return (
            <div key={index}>
              <ClaimDataCard
                width={width}
                data={fetchClaimData}
                title="Claims Data Log"
                setNetwork={setNetwork}
              />
            </div>
          );
        }
        return null;
      });
    }
    return DataNotFoundSection;
  }, [
    blockRes,
    l2TxRes,
    fetchClaimRes,
    isNumericHash,
    setNetwork,
    onSetSubscription,
  ]);

  return <div>{handleDisplay()}</div>;
};

export default NetworkSelector;
