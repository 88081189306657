import {
  InterpreterObjectType,
  TransformedTemplate,
  TargetInstance,
} from "hooks/useGlobalIndex/types";
import { useState, useEffect } from "react";

type AliasResult = {
  loading: boolean;
  alias: string | undefined;
};

export default function useCheckIssuerAlias(
  issuer: string,
  interpreter: Record<string, InterpreterObjectType> | null,
  contractTemplates: Record<string, TransformedTemplate>,
  contractInstance: Record<string, TargetInstance>
): AliasResult {
  const [loading, setLoading] = useState(true);
  const [alias, setAlias] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    const allTemplates = {
      ...interpreter,
      ...contractTemplates,
      ...contractInstance,
    };

    setTimeout(() => {
      const actualTxId = issuer.slice(
        issuer.indexOf("{") + 22,
        issuer.indexOf('"}')
      );

      const cleanedTxId = actualTxId.startsWith("0x")
        ? actualTxId.substring(2)
        : actualTxId;

      if (allTemplates.hasOwnProperty(cleanedTxId)) {
        setAlias(allTemplates[cleanedTxId].alias);
      } else {
        setAlias(undefined);
      }

      setLoading(false); 
    }, 500); 

  }, [issuer, interpreter, contractTemplates, contractInstance]);

  return { loading, alias };
}
