import "./L2TxLogCard.css";
import { memo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { NetworkName } from "../../generated/graphql";
import Typography from "@mui/material/Typography";
import jsonParseRawContent from "utils/jsonParseRawContent";
import { RawContentData } from "utils/types";
import findRawDataElement from "utils/findRawDataElement";
import createRawContentState from "utils/createRawContentState";
import handleTxIds from "utils/handleTxIds";
import CircularProgress from "@mui/material/CircularProgress";
import {
  L2TxLogMuiCardContent,
  L2TxLogDivider,
  L2TxLogMuiCard,
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import { FullTxLogFragment, ContactExecutedGql } from "../../generated/graphql";
import { FullTxLogFragment as devFullTxLogFragment } from "../../generated/graphql-devnet";
import RawContentModal from "../Modals/RawContentModal";
import RawContentVisializerModal from "../ModalsVisializer/RawContentVisializerModal";
import { BlockChains } from "utils/constants";
import TxContactExecutedSection from "./TxContactExecutedSection";
import TxLoadedSection from "./TxLoadedSection";
import LegacyCwebTxLoadedSection from "./LegacyCwebTxLoadedSection";
import TxLogErrorSection from "./TxLogErrorSection";
import { Fade, Tooltip } from "@mui/material";
import CopyIcon from "components/CopyIcon";
import useCheckIssuerAlias from "utils/checkIssuerAlias";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import { Alias } from "shared/components";

type L2TxLogCardProps = {
  title?: string;
  data: FullTxLogFragment | devFullTxLogFragment;
  width: any;
  setNetwork: (n: NetworkName | undefined) => void;
};

const L2TxLogCard = memo((props: L2TxLogCardProps) => {
  const { title, data, width, setNetwork } = props;
  const blockchain = BlockChains[data.network];
  const [rawTxLoaded, setRawTxLoaded] = useState("");
  const [rawContextJsonObj, setRawContextJsonObj] = useState<RawContentData>();
  const [rawContext, setRawContext] = useState(false);
  const [rawContextVisualiser, setRawContextVisualiser] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [alias, setAlias] = useState<string | undefined>(undefined);
  console.log("l2data", data);

  const { contractTemplates, interpreters, contractInstance } =
    useGlobalIndex();
  const handleClose = () => {
    setRawContext(false);
  };
  const handleCloseVisializer = () => {
    setRawContextVisualiser(false);
    const newSearchParams = new URLSearchParams(searchParams);

    if (newSearchParams.has("visualizer")) {
      newSearchParams.delete("visualizer"); // Remove the parameter if value is undefined
    }
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (data?.network) {
      setNetwork(data?.network as unknown as NetworkName);
    }
  }, [data, setNetwork]);
  useEffect(() => {
    if (searchParams.get("visualizer") === "true") {
      setRawContextVisualiser(true);
    }
  }, [searchParams]);
  useEffect(() => {
    if (data?.rawContent) {
      setRawTxLoaded(JSON.stringify(data.rawContent));
      const jsonRaw = jsonParseRawContent(JSON.stringify(data.rawContent));
      let jsonrawObj = createRawContentState(jsonRaw);
      setRawContextJsonObj(jsonrawObj);
    }
  }, [data?.rawContent]);

  const aliasResult = useCheckIssuerAlias(
    data?.txIssuer,
    interpreters,
    contractTemplates,
    contractInstance
  );
  useEffect(() => {
    console.log("aliasResult:", aliasResult);
  }, [aliasResult]);
  useEffect(() => {
    if (aliasResult.loading && alias !== "loading") {
      setAlias("loading");
    } else if (
      !aliasResult.loading &&
      aliasResult.alias &&
      alias !== aliasResult.alias
    ) {
      setAlias(aliasResult.alias);
    } else if (
      !aliasResult.loading &&
      !aliasResult.alias &&
      alias !== undefined
    ) {
      setAlias(undefined);
    }
  }, [aliasResult, alias]);

  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <L2TxLogMuiCard sx={{ minWidth: 100 }}>
        <L2TxLogMuiCardContent sx={{ minWidth: 100 }}>
          <div
            className="L2TxLogInfo-container"
            style={{ textIndent: "0px !important" }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <div className="LogContent-inner" style={{ height: "100%" }}>
                <div className="Center-container">
                  <div
                    className={
                      width > MOBILE_WIDTH ? "Center" : "Center-mobile"
                    }
                    style={{
                      position: "relative",
                    }}
                  >
                    <div id="L2TxLogCard-header">
                      {title && (
                        <Typography className="BoldFont16" align="center">
                          {title}
                        </Typography>
                      )}

                      {rawTxLoaded !== null && (
                        <div
                          style={
                            width > MOBILE_WIDTH
                              ? { top: "0px" }
                              : { top: "-15px" }
                          }
                        >
                          {
                            <>
                              <div id="RawTxLog-container">
                                <button
                                  type="button"
                                  id="RawContent-button"
                                  style={{
                                    color: "rgb(0, 195, 255)",
                                  }}
                                  onClick={() => setRawContext(true)}
                                >
                                  Raw Content
                                </button>
                              </div>
                              <div id="RawTxLog-container">
                                <button
                                  type="button"
                                  id="RawContent-button"
                                  style={{
                                    color: "rgb(0, 195, 255)",
                                  }}
                                  onClick={() => {
                                    setRawContextVisualiser(true);
                                    const newSearchParams = new URLSearchParams(
                                      searchParams
                                    );

                                    // Append the new search param
                                    newSearchParams.append(
                                      "visualizer",
                                      "true"
                                    );

                                    // Set the new search params
                                    setSearchParams(newSearchParams);
                                  }}
                                >
                                  Visualize Transaction{" "}
                                </button>
                              </div>
                            </>
                          }
                        </div>
                      )}
                    </div>
                    <div className="L2TxLogCard-data-container">
                      <img
                        style={{ marginTop: "10px" }}
                        id="Block-icon"
                        src={blockchain.icon}
                        alt="bc"
                      />
                      <Typography className="BoldFont16">
                        {blockchain.networkName}
                      </Typography>
                      <Typography className="BoldFont14" align="center">
                        Block Height #{data.blockHeight}
                      </Typography>
                      <Typography className="BoldFont14" gutterBottom>
                        Execution Step #{data.execStep}
                      </Typography>
                      <div
                        className={
                          width > MOBILE_WIDTH
                            ? "L2TxLogCard-data-table"
                            : "L2TxLogCard-data-table-mobile"
                        }
                      >
                        <div>
                          <div>
                            <Typography className="BoldFont16" align="left">
                              Issuer
                            </Typography>
                            {alias === "loading" ? (
                              <CircularProgress />
                            ) : alias === undefined ? (
                              <Typography
                                className="BoldFont14"
                                align="center"
                                gutterBottom
                              >
                                {data.txIssuer}
                              </Typography>
                            ) : (
                              <Alias alias={alias} />
                            )}
                          </div>
                        </div>
                        <div>
                          <Typography className="BoldFont16" align="left">
                            Transaction ID
                          </Typography>
                          <div className="L2TxId-row">
                            <Typography
                              className="BoldFont14 L2TxId-container"
                              align="center"
                            >
                              {data.txid ? (
                                <>
                                  <Tooltip
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          width: "min-content !important",
                                          backgroundColor: "#4f4f72 !important",
                                          padding: "0px !important",
                                          marginTop: "-1px !important",
                                        },
                                      },
                                    }}
                                    disableFocusListener
                                    TransitionComponent={Fade}
                                    title={
                                      <div id="TxId-tooltip">{data.txid}</div>
                                    }
                                  >
                                    <div
                                      onClick={() => {
                                        setSearchParams({ search: data.txid });
                                      }}
                                      className="L2TxId-container"
                                    >
                                      {handleTxIds(data.txid)}
                                    </div>
                                  </Tooltip>
                                </>
                              ) : (
                                "N/A"
                              )}
                            </Typography>
                            <CopyIcon data={data.txid} />
                          </div>
                        </div>
                      </div>
                      <L2TxLogDivider variant="middle" />

                      <div
                        className="L2TxLogCard-data-table-mobile"
                        key="L2TxContainer"
                      >
                        <div
                          className={
                            width > MOBILE_WIDTH
                              ? "L2TxLogCard-data-cell"
                              : "L2TxLogCard-data-cell-mobile"
                          }
                        >
                          {Array.isArray(data?.txLogInfo) &&
                            data?.txLogInfo?.map((logInfo, index) => {
                              if (logInfo.__typename === "TxLoadedGql")
                                return (
                                  <div key={index}>
                                    <TxLoadedSection
                                      data={logInfo}
                                      width={width}
                                      networkName={blockchain.networkName}
                                      key={index}
                                    />
                                    <L2TxLogDivider variant="middle" />
                                  </div>
                                );
                              if (logInfo.__typename === "ContactExecutedGql") {
                                if (rawContextJsonObj) {
                                  const element = findRawDataElement(
                                    rawContextJsonObj,
                                    logInfo
                                  );
                                  return (
                                    <>
                                      <TxContactExecutedSection
                                        key={index}
                                        data={
                                          logInfo as unknown as ContactExecutedGql
                                        }
                                        width={width}
                                        rawData={element}
                                      />
                                      <L2TxLogDivider variant="middle" />
                                    </>
                                  );
                                }
                              }

                              if (logInfo.__typename === "TxLogErrorGql")
                                return (
                                  <>
                                    <TxLogErrorSection
                                      key={index}
                                      data={logInfo}
                                      width={width}
                                    />
                                    <L2TxLogDivider variant="middle" />
                                  </>
                                );
                              if (
                                logInfo.__typename === "LegacyCwebTxLoadedGql"
                              )
                                return (
                                  <>
                                    <LegacyCwebTxLoadedSection
                                      key={index}
                                      data={logInfo}
                                      width={width}
                                      network={blockchain.networkName}
                                    />
                                    <L2TxLogDivider variant="middle" />
                                  </>
                                );
                              return null;
                            })}
                        </div>
                        <RawContentModal
                          open={rawContext}
                          handleClose={handleClose}
                          data={rawTxLoaded}
                        />
                        <RawContentVisializerModal
                          handleClose={handleCloseVisializer}
                          open={rawContextVisualiser}
                          network={blockchain.networkName}
                          txId={data.txid}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </L2TxLogMuiCardContent>
      </L2TxLogMuiCard>
    </div>
  );
});
export default memo(L2TxLogCard);
