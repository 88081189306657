import "./L2TxLogCard.css";
import { memo, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import handleTxIds from "utils/handleTxIds";
import { trimStrings } from "utils/trimJsonString";
import TxContractExecutedRow from "./TxContractExecutedRow";
import { ContactExecutedGql } from "../../generated/graphql";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";

type TxContactExecutedSectionProps = {
  data: ContactExecutedGql;
  width: any;
  rawData: any;
};

const TxContactExecutedSection = (props: TxContactExecutedSectionProps) => {
  const { data, width, rawData } = props;
  const [, setSearchParams] = useSearchParams();

  const memoizedHandleTxIds = useMemo(
    () => (txid: string) => handleTxIds(txid),
    []
  );
  const RenderTxIdTypography = memo(({ txid }: { txid: string }) => {
    // console.log("rendertx", txid);
    return (
      <div className="L2TxId-row" style={{ margin: "5px auto 10px auto" }}>
        <Typography className="BoldFont14 L2TxId-container" align="center">
          {txid ? (
            <>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#4f4f72 !important",
                      padding: "0px !important",
                      marginTop: "-1px !important",
                    },
                  },
                }}
                disableFocusListener
                TransitionComponent={Fade}
                title={<div id="TxId-tooltip">{txid}</div>}
              >
                <div
                  className="L2TxId-container"
                  onClick={() => {
                    setSearchParams({ search: txid });
                  }}
                  id="TxId-container"
                >
                  {memoizedHandleTxIds(txid)}
                </div>
              </Tooltip>
            </>
          ) : (
            "N/A"
          )}
        </Typography>

        <CopyIcon data={txid} />
        <img
          className="linkArrow"
          src={ActiveSwipe}
          style={{ transform: "rotate(180deg)", marginLeft: "5px" }}
          alt="swipe"
          onClick={() => {
            setSearchParams({ search: txid });
          }}
        ></img>
      </div>
    );
  });

  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div>
        <Typography className="BoldBlueFont16">Contract executed</Typography>
      </div>
      <div id="Carousel" style={{ textIndent: "0px !important" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className="ParentsCarousel-inner" style={{ height: "100%" }}>
            <div
              style={{ marginTop: "10px" }}
              className="L2TxLogCard-data-table  L2TxLogInfo-container"
            >
              <div className="gridRightBorder">
                <Typography className="BoldFont16">Details:</Typography>
                {rawData[0]?.callOp?.smart_contract && (
                  <div className="callOpContainer">
                    <Typography className="BoldWhiteFont14">
                      smart contract:
                    </Typography>
                    {rawData[0]?.callOp?.smart_contract
                      .ComputationBlockContract ? (
                      <RenderTxIdTypography
                        txid={
                          rawData[0]?.callOp?.smart_contract
                            ?.ComputationBlockContract
                        }
                      />
                    ) : (
                      <Typography
                        sx={{ margin: "5px 0px 10px 0px" }}
                        className="BoldFont14"
                      >
                        {JSON.stringify(
                          trimStrings(rawData[0]?.callOp?.smart_contract),
                          null,
                          2
                        )}
                      </Typography>
                    )}
                    {rawData[0]?.callOp?.number_of_arguments && (
                      <div className="callOpContainer">
                        <Typography className="BoldWhiteFont14">
                          number of operations:
                        </Typography>
                        <Typography className="BoldFont14">
                          {rawData[0]?.callOp?.number_of_arguments}
                        </Typography>
                      </div>
                    )}
                    {rawData[0]?.callOp?.resources && (
                      <div className="callOpContainer">
                        <Typography className="BoldWhiteFont14">
                          resources:
                        </Typography>
                        <Typography
                          className="BoldFont14"
                          sx={{ margin: "5px 0px 10px 0px" }}
                        >
                          {rawData[0]?.callOp?.resources}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
                <TxContractExecutedRow row={rawData} />
              </div>
              <div>
                {data?.children?.map((child: any, index: number) => {
                  return (
                    <div key={index}>
                      <Typography className="BoldFont16">
                        Child Tx #{index + 1}
                      </Typography>
                      <RenderTxIdTypography txid={child} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(TxContactExecutedSection);
